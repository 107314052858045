import React, { useEffect, useReducer, useMemo, useContext } from "react";
import * as firebase from "firebase";
import { gql } from "@apollo/client";
import client from "./index";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "antd/dist/antd.css";

import AuthContext from "./context";
// import App from './App'
import Reader from "./Reader/Reader";
import ListEvent from "./Event/ListEvent";
import Info from "./Info/Info";
import Login from "./Auth/Login";
import Header from "./Templates/Header";
import AllCheckpoints from "./Checkpoints/AllCheckpoints";
// import ReaderNFC from "./Reader/ReaderNFC";
import LinktoUser from "./Event/LinktoUser";
import ReaderQR from "./Reader/ReaderQR";

const GET_USER = gql`
  query getUser($userId: String!) {
    userOne(filter: { userId: $userId }) {
      userId
      organizId
      email
      _id
    }
  }
`;

function PrivateRoute({ children, ...rest }) {
  const { isLoading, userId } = useContext(AuthContext);

  if (isLoading) return <div>checking auth.</div>;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return userId ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        );
      }}
    />
  );
}

/* function Logout() {
  const history = useHistory()
  const clearCheckIn = useIndexedDB('checkIn').clear

  return (
    <button
      onClick={async () =>  {
        await clearCheckIn()
        await firebase.auth().signOut()
        history.replace('/login')
      }}
    >
      Logout
    </button>
  )
} */

export default function RouterConfig() {
  // const { client } = useQuery(GET_USER, { variables: { userId: "" }})
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userId: action.userId,
            userInfo: action.userInfo,
            isLoading: false,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            isSignout: false,
            userId: action.userId,
            userInfo: action.userInfo,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isLoading: false,
            isSignout: true,
            userId: null,
            userInfo: {},
          };
        default:
          return;
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userId: null,
      userInfo: {},
    }
  );

  useEffect(() => {
    document.title = "Checkpointer | Thairun";
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const { data } = await client.query({
          query: GET_USER,
          variables: { userId: user.uid },
        });
        if (!data.userOne) {
          dispatch({ type: "SIGN_OUT" });
          return;
        }
        const userInfo = data.userOne;
        dispatch({ type: "RESTORE_TOKEN", userId: user.uid, userInfo });
      } else {
        dispatch({ type: "SIGN_OUT" });
      }
    });
  }, []);

  const authContext = useMemo(
    () => ({
      isLoading: state.isLoading,
      userId: state.userId,
      userInfo: state.userInfo,
      signIn: async (data) => {
        return firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            return firebase
              .auth()
              .signInWithEmailAndPassword(data.email, data.password)
              .then(({ user }) => {
                return client
                  .query({
                    query: GET_USER,
                    variables: {
                      userId: user.uid,
                    },
                    fetchPolicy: "network-only",
                  })
                  .then(({ data }) => {
                    if (!data.userOne) {
                      throw Error("ไม่พบข้อมูลในฐานข้อมูล");
                    }
                    const userInfo = data.userOne;
                    dispatch({ type: "SIGN_IN", userId: user.uid, userInfo });
                    return userInfo;
                  });
              });
          })
          .catch((error) => {
            throw error;
          });
      },
      signOut: () => dispatch({ type: "SIGN_OUT" }),
    }),
    [state.isLoading, state.userId, state.userInfo]
  );

  return (
    <AuthContext.Provider value={authContext}>
      <Router>
        {state.userId && <Header />}
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              if (state.isLoading) {
                return <div>Checking Auth</div>;
              }
              return state.userId ? (
                <Redirect to={{ pathname: "/event" }} />
              ) : (
                <Login />
              );
            }}
          />
          <Route
            path="/login"
            render={() => {
              if (state.isLoading) {
                return <div>Checking Auth</div>;
              }
              return state.userId ? (
                <Redirect to={{ pathname: "/event" }} />
              ) : (
                <Login />
              );
            }}
          />
          <PrivateRoute>
            {/* <Route path="/userData">
              <userData />
            </Route> */}
            <Route path="/event">
              <ListEvent />
            </Route>
            <Route path="/reader">
              <Reader />
            </Route>
            {/* <Route path="/readerNFC">
              <ReaderNFC />
            </Route> */}
            <Route path="/readerNFC">
              <ReaderQR />
            </Route>
            <Route path="/info">
              <Info />
            </Route>
            <Route path="/all">
              <AllCheckpoints />
            </Route>
            {/* <Route path="/Test">
              <Test />
            </Route> */}
            <Route path="/LinktoUser">
              <LinktoUser />
            </Route>
          </PrivateRoute>
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}
