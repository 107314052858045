import React, { useState, useEffect, useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { Modal, Select, List, Typography, Layout, Button } from "antd";
import { useIndexedDB } from "react-indexed-db";
import { useHistory } from "react-router-dom";
import AuthContext from "./../context/index";

const GET_EVENTS = gql`
  query getEventByOrganiz($organizId: MongoID) {
    eventMany(filter: { organizId: $organizId, deleted: false }) {
      name
      slug
      startTime
      endTime
      checkpoints {
        name
        position
        cutOffTime
      }
      _id
    }
  }
`;
const GET_ORGANIZ = gql`
  query getOrganiz($id: MongoID!) {
    organizById(_id: $id) {
      name
      _id
    }
  }
`;
// const GET_RUNS_USERS = gql`
//   query getRunUsers($slug: String!) {
//     usersBySlug(slug: $slug) {
//       name
//       bib
//       RFID
//       slug
//     }
//   }
// `;
const GET_ECENT_SLUG = gql`
  query getEventsBySlug($slug: String!) {
    eventsBySlug(slug: $slug) {
      name
      slug
    }
  }
`;
// const FIND_USER = gql`
//   query findUser($slug: String!, $bib: Int!) {
//     searchByBibToSlug(slug: $slug, bib: $bib) {
//       name
//       bib
//       RFID
//       slug
//     }
//   }
// `;
function ListEvent() {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedSlug, setselectedSlug] = useState(null);
  const [selectedPos, setSelectedPos] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  // const [linkNFCData, setLinkNFCData] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const { loading: loadEvents, data: events } = useQuery(GET_EVENTS, {
    variables: { organizId: userInfo.organizId },
    fetchPolicy: "cache-and-network",
  });
  const { loading: loadOrganiz, data: organizer } = useQuery(GET_ORGANIZ, {
    variables: { id: userInfo.organizId },
  });
  // const { loading: loadRunUsers, refetch } = useQuery(GET_RUNS_USERS, {
  //   variables: { slug: "" },
  //   skip: true,
  // });
  const { loading: loadEvenBySlug, refetch: eventRefetch } = useQuery(
    GET_ECENT_SLUG,
    {
      variables: { slug: "" },
      skip: true,
    }
  );
  // const { refetch: userRefetch } = useQuery(FIND_USER, {
  //   variables: { slug: "", bib: 1 },
  //   skip: true,
  // });
  const { add, getAll } = useIndexedDB("checkIn");
  // const { clear } = useIndexedDB("userRunIndex");
  // const NewRunUsers = useIndexedDB("userRunIndex").add;
  const history = useHistory();
  useEffect(() => {
    async function checkData() {
      const results = await getAll();
      if (results.length > 0) {
        history.replace("/info");
      }
    }

    checkData();
  });
  // refetch
  // useMemo(() => {
  //   if (events) {
  //     refetch({ slug: events?.eventMany[0].slug }).then((res) => {
  //       clear();
  //       // saveDataRunUsers();
  //       console.log(res.data.usersBySlug);
  //       res.data.usersBySlug.map(async (e) => {
  //         await NewRunUsers({
  //           bib: e.bib,
  //           name: e.name,
  //           RFID: e.RFID,
  //           slug: e.slug,
  //         });
  //       });
  //     });
  //   }
  // }, [NewRunUsers, clear, events, refetch]);
  if (loadEvenBySlug) return <div>loading EvenToSlug</div>;
  // if (loadRunUsers) return <div>loading RunUsers</div>;
  if (loadEvents) return <div>loading Events</div>;
  if (loadOrganiz) return <div>loading Organiz</div>;
  const { eventMany } = events;
  const { organizById } = organizer;
  const closeLinkModal = () => setLinkModal(false);
  const closeModal = () => setVisibleModal(false);
  // const closeLinkNFCModal = () => setLinkNFCModal(false);
  const handleOk = async () => {
    if (!selectedPos) {
      Modal.error({
        title: "กรุณาเลือกตำแหน่งที่จะเช็คอิน",
      });
      return;
    }
    const checkInData = {
      position: selectedPos,
      slug: selectedEvent.slug,
      name: selectedEvent.name,
      userId: userInfo.userId,
    };
    await add(checkInData);
    setVisibleModal(false);
    history.push("/info");
  };

  // const handleClaim = async () => {
  //   history.push("/ClaimReward", events.eventMany[0].slug);
  // };

  const handleLinkOk = async (value) => {
    setselectedSlug(value);
    // setLinkModal(false);
  };

  const handleSearch = async () => {
    setLinkModal(true);
  };
  const handleSlug = async () => {
    // console.log(slug);
    // selectedSlug
    console.log(selectedSlug);
    await eventRefetch({
      slug: selectedSlug,
    }).then((res) => {
      // const numberNFC = res.data.searchByBibToSlug.RFID;
      console.log(res.data.eventsBySlug, "res.data.searchByBibToSlug");
      const dataRunner = res.data.eventsBySlug;
      history.push("/LinktoUser", dataRunner.slug);
    });
  };
  const handleSelectEvent = (index) => {
    setVisibleModal(true);
    const event = eventMany[index];
    setSelectedEvent(event);
  };
  const handleSelectPosition = (value) => {
    setSelectedPos(value.toString());
  };
  // const handleLinkToUser = () => {
  //   setLinkNFCModal(true);
  // };
  return (
    <Layout>
      <Layout.Content
        style={{
          padding: 10,
          backgroundColor: "#fff",
        }}
      >
        <Typography.Title level={3}>{organizById.name}</Typography.Title>
        <List
          bordered
          dataSource={eventMany}
          renderItem={(event, index) => (
            <List.Item onClick={() => handleSelectEvent(index)}>
              <Typography.Title level={4}>{event.name}</Typography.Title>
            </List.Item>
          )}
        />
        <Typography.Title level={3}>link NFC to User</Typography.Title>

        <Button onClick={handleSearch}> ผูก NFC กับนักวิ่ง </Button>

        {/* <Typography.Title level={3}>Claim Reward</Typography.Title>
        <Button onClick={handleClaim}> Claim </Button> */}
      </Layout.Content>
      <Modal
        title="ตำแหน่งที่จะเช็คอิน"
        visible={visibleModal}
        onCancel={closeModal}
        onOk={handleOk}
      >
        <Select style={{ width: 200 }} onChange={handleSelectPosition}>
          {selectedEvent &&
            selectedEvent.checkpoints.map(({ position, name }) => {
              return (
                <Select.Option key={position} value={position}>
                  {name} | ตำแหน่งที่ {position}
                </Select.Option>
              );
            })}
        </Select>
      </Modal>
      <Modal
        title="เลือก Slug ที่ต้องการผูก NFC"
        visible={linkModal}
        onCancel={closeLinkModal}
        onOk={handleSlug}
      >
        <Select style={{ width: 200 }} onChange={handleLinkOk}>
          {events.eventMany &&
            events.eventMany.map(({ slug }) => {
              return (
                <Select.Option key={slug} value={slug}>
                  Slug Even {slug}
                </Select.Option>
              );
            })}
        </Select>
      </Modal>
    </Layout>
  );
}

export default ListEvent;
