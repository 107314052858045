import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIndexedDB } from "react-indexed-db";
import {
  Layout,
  List,
  Button,
  Typography,
  Space,
  Empty,
  Modal,
  message,
  Tabs,
} from "antd";
import { gql, useMutation } from "@apollo/client";
import { Detector } from "react-detect-offline";
import _reverse from "lodash/reverse";
import _get from "lodash/get";
// import useData from "../UsersData/useData";

const PUSH_CHECKPOINT = gql`
  mutation pushCheckpoints($record: [CreateManycheckpointInput]) {
    checkpointCreateMany(records: $record) {
      records {
        _id
        slug
        time
        bib
        position
      }
    }
  }
`;
function Info() {
  const [checkIn, setCheckIn] = useState(null);
  const [checkpoints, setCheckpoints] = useState([]);
  const history = useHistory();

  // const data = useData();
  // console.log(data);

  const [pushCheckpoint] = useMutation(PUSH_CHECKPOINT);
  const getAllCheckIn = useIndexedDB("checkIn").getAll;
  // const getUserIndex = useIndexedDB("UserIndex").getAll;
  // const clearCheckIn = useIndexedDB('checkIn').clear
  const getAllCheckpoint = useIndexedDB("checkpoint").getAll;
  const clearCheckpoint = useIndexedDB("checkpoint").clear;
  const updateCheckpoint = useIndexedDB("checkpoint").update;
  useEffect(() => {
    async function init() {
      // let UserData = await getUserIndex();
      let checkInData = await getAllCheckIn();
      let checkpointData = await getAllCheckpoint();
      // console.log(UserData);
      _reverse(checkpointData);
      if (checkInData.length === 0) {
        history.replace("/event");
        return;
      }
      const currentPos = _get(checkInData, "0.position");
      const currentUserId = _get(checkInData, "0.userId");
      checkpointData = checkpointData.filter(
        ({ position, userId }) =>
          position === currentPos && userId === currentUserId
      );
      setCheckIn(checkInData[0]);
      setCheckpoints(checkpointData);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenCam = () => history.push("/reader");
  const handleOpenNFC = () => history.push("/readerNFC");
  const handlePushData = async () => {
    const prepareData = checkpoints
      .filter(({ status }) => status === "pending")
      .map(({ _id, bib, position, slug, time, userId }) => ({
        _id,
        bib: parseInt(bib, 10),
        position: parseInt(position, 10),
        slug,
        time,
        userId,
      }));
    if (prepareData.length === 0) return;

    try {
      await pushCheckpoint({
        variables: {
          record: prepareData,
        },
      });
      const updatedCheckpoints = checkpoints.map(
        ({ id, _id, position, slug, time, userId }) => {
          const data = {
            id,
            _id,
            position,
            slug,
            time,
            userId,
            status: "sent",
          };
          updateCheckpoint(data);
          return data;
        }
      );
      setCheckpoints(updatedCheckpoints);
      message.success("ส่งข้อมูลเรียบร้อย");
      // await clearCheckpoint()
      // setCheckpoints([])
    } catch (e) {
      console.error("e", e);
      // await clearCheckpoint()
      // setCheckpoints([])
    }
  };
  const handleClearData = async () => {
    Modal.confirm({
      title: "ต้องการล้างข้อมูลการเช็คอินหรือไม่ ?",
      content: "ถ้าลบแล้วจะไม่สามารถกู้ข้อมูลคืนได้",
      onOk: async () => {
        await clearCheckpoint();
        setCheckpoints([]);
      },
      onCancel: () => {
        return;
      },
    });
  };

  if (!checkIn) {
    return <div>checking...</div>;
  }
  const pendingData = checkpoints.filter(({ status }) => status === "pending");
  const sentData = checkpoints.filter(({ status }) => status === "sent");
  const isPendingEmpty = pendingData.length === 0;
  const isSentEmpty = sentData.length === 0;

  const $compPending = (
    <div style={{ maxHeight: 300, overflow: "scroll", padding: 10 }}>
      <List
        bordered
        dataSource={pendingData}
        renderItem={(item) => {
          const time = new Date(item.time);
          return (
            <List.Item>
              {item.position} (pos.) |{item.bib} (bib) |
              {time.toLocaleTimeString("th-TH")} |{item.status}
            </List.Item>
          );
        }}
      />
    </div>
  );
  const $compSent = (
    <div style={{ maxHeight: 300, overflow: "scroll", padding: 10 }}>
      <List
        bordered
        dataSource={sentData}
        renderItem={(item) => {
          const time = new Date(item.time);
          return (
            <List.Item>
              {item.position} (pos.) |{item.bib} (bib) |
              {time.toLocaleTimeString("th-TH")} |{item.status}
            </List.Item>
          );
        }}
      />
    </div>
  );
  return (
    <Layout>
      <Layout.Content
        style={{
          padding: 10,
          backgroundColor: "#fff",
        }}
      >
        <Space style={{ width: "100%" }} direction="vertical" size={10}>
          <div style={{ textAlign: "center" }}>
            <Typography.Title level={4}>
              {checkIn.name} ตำแหน่งที่ {checkIn.position}
            </Typography.Title>
          </div>
          <Tabs defaultActiveKey="1" centered>
            <Tabs.TabPane tab={`Pending (${pendingData.length})`} key="1">
              {!isPendingEmpty ? $compPending : <Empty />}
            </Tabs.TabPane>
            <Tabs.TabPane tab={`Sent (${sentData.length})`} key="2">
              {!isSentEmpty ? $compSent : <Empty />}
            </Tabs.TabPane>
          </Tabs>
          <div>
            <Button size="large" type="primary" onClick={handleOpenCam} block>
              เปิดกล้อง
            </Button>
          </div>
          <div>
            <Button size="large" type="primary" onClick={handleOpenNFC} block>
              เปิดสแกนด้วยเครื่องอ่าน QR
            </Button>
          </div>
          {!isPendingEmpty && (
            <Space direction="vertical" style={{ width: "100%" }} size={20}>
              <Detector
                render={({ online }) => {
                  return (
                    <Button
                      size="large"
                      onClick={handlePushData}
                      disabled={!online}
                      block
                    >
                      {online ? `ส่งข้อมูล (${pendingData.length})` : "ออฟไลน์"}
                    </Button>
                  );
                }}
              />
              <Button
                size="large"
                onClick={handleClearData}
                type="danger"
                block
              >
                ล้างข้อมูล
              </Button>
            </Space>
          )}
        </Space>
      </Layout.Content>
    </Layout>
  );
}

export default Info;
