import React, { useState, useEffect } from 'react'
import { notification, Button, Tabs, Empty, List } from "antd";
import { useHistory } from 'react-router-dom'
import { useIndexedDB } from 'react-indexed-db'
import bsonObjectid from 'bson-objectid'
import Moment from 'moment';
import _get from 'lodash/get'
import _reverse from 'lodash/reverse'
// import useSound from 'use-sound'
// import pipEffect from './pip.mp3'
// import errEffect from './error2.wav'

let textRFID = "";
let rfid = "";

function Reader() {
  // const [play] = useSound(pipEffect)
  // const [playErr] = useSound(errEffect)
  // const [bgFlash, setBgFlash] = useState(false)
  const [checkIn, setCheckIn] = useState([])
  const [checkpoints, setCheckpoints] = useState([]);
  const [result, setResult] = useState('')
  const { getAll } = useIndexedDB('checkIn')
  const { add } = useIndexedDB('checkpoint')
  const getAllCheckpoint = useIndexedDB("checkpoint").getAll;
  const history = useHistory()


  useEffect(() => {
    window.addEventListener("keypress", keyListener);
    // console.log("first listener");
    return () => {
      // console.log("xxx");
      window.removeEventListener("keypress", keyListener);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function setDataCheckIn() {
      const results = await getAll()
      let checkpointData = await getAllCheckpoint();
      _reverse(checkpointData);
      console.log(results)
      if (results.length === 0) {
        history.replace('/event')
        return
      }
      setCheckIn(results)
      setCheckpoints(checkpointData)
    }
    setDataCheckIn()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   const interval = setInterval(async() => {
  //     console.log('This will run every second!');
  //     let checkpointData = await getAllCheckpoint();
  //     _reverse(checkpointData);
  //     setCheckpoints(checkpointData);
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, []);

  const splitData = (url, currentCP) => {
    console.log('split', url)
    const arrData = url.split('/')
    const bib = arrData.pop()
    const slug = arrData.pop()
    const currentSlug = _get(checkIn, '0.slug', '') ||  _get(currentCP, '0.slug', '')
    console.log(currentSlug)

    console.log({bib, slug, currentSlug})
    
    if (slug !== currentSlug) {
      return {
        error: `Slug จาก QR Code: ${slug} ไม่ตรงกันกับ Checkin: ${currentSlug}`
      }
    }
    
    return {
      bib,
      slug
    }
  }

  async function keyListener(e) {
    if (e.key === "Enter") {
      textRFID = rfid;
      rfid = "";
      console.log(textRFID, 'textRFID');
      const results = await getAll();
      
      if (results.length === 0) {
        if (textRFID === result) return;
        // หน้าเลือกตำแหน่ง cp
        history.replace("/event");
        return;
      }
      console.log(results, 'results')
      const { bib, slug, error } = splitData(textRFID, results)
      console.log(bib, slug, error)
      // let runUserData = null;
      
      if(!bib) return
      // setBgFlash(true);
      // // ทำการเซิฟข้อมูลลงในเครื่อง
      const checkpointData = {
        _id: bsonObjectid().toHexString(),
        bib: bib,
        slug: slug,
        time: Date.now(),
        position: _get(results, "0.position"),
        userId: _get(results, "0.userId"),
        status: "pending",
      };
      notification.info({
        // message: `${textRFID}`,
        message: `เพิ่ม bib ${bib} เรียบร้อย`,
        description: Moment(Date.now()).format("YYYY/MM/DD HH:mm:ss"),
        duration: 2,
        placement: "top",
      });
      await add(checkpointData);
      let checkpointDatas = await getAllCheckpoint();
      _reverse(checkpointDatas);
      setCheckpoints(checkpointDatas);
      // // เสียงตอนสแกน
      // playRef.current.play();
      setResult(textRFID);
      // setTimeout(() => setBgFlash(false), 300);
    } else {
      rfid += e.key;
    }
  }

  const handleClose = () => history.replace('/info')

  const pendingData = checkpoints.filter(({ status }) => status === "pending");
  console.log(pendingData, "pendingData");
  const isPendingEmpty = pendingData.length === 0;
  const $compPending = (
    <div style={{ maxHeight: 300, overflow: "scroll", padding: 10 }}>
      <List
        bordered
        dataSource={pendingData}
        renderItem={(item) => {
          const time = new Date(item.time);
          return (
            <List.Item>
              {item.position} (pos.) |{item.bib} (bib) |
              {time.toLocaleTimeString("th-TH")} |{item.status}
            </List.Item>
          );
        }}
      />
    </div>
  );

  return (
    <>
      {/* {contextHolder} */}
      <div style={{ textAlign: "center", fontSize: "2em" }}>หน้าสแกน QR</div>
      <Tabs defaultActiveKey="1" centered>
        <Tabs.TabPane tab={`Pending (${pendingData.length})`} key="1">
          {!isPendingEmpty ? $compPending : <Empty />}
        </Tabs.TabPane>
      </Tabs>
      <div>
        โดยมีขั้นตอนในการทำก่อนที่จะสแกนดังนี้
        <br />
        <span>1. นำเครื่องสแกน QR ไป bluetooth กับโทรศัพท์</span>
        <br />
        <span>2. ดูแสงไฟที่เครื่องสแกนว่ามันขึ้นแสงสีน้ำ(ไม่กะพริบ)</span>
        <br />
        <span>3. เตรียมพร้อมสแกน</span>
      </div>
      <div style={{ padding: 10 }}>
        <Button type="danger" size="large" onClick={handleClose} block>
          ปิดสแกน QR
        </Button>
      </div>
    </>
  )
}

export default Reader