import React, { useState, useEffect } from 'react'
import QrReader from 'react-qr-reader'
import { Layout, Button, Space, notification } from 'antd'
import { useHistory } from 'react-router-dom'
import { useIndexedDB } from 'react-indexed-db'
import bsonObjectid from 'bson-objectid'
import _get from 'lodash/get'
import useSound from 'use-sound'
import pipEffect from './pip.mp3'
import errEffect from './error2.wav'

function Reader() {
  const [play] = useSound(pipEffect)
  const [playErr] = useSound(errEffect)
  const [bgFlash, setBgFlash] = useState(false)
  const [checkIn, setCheckIn] = useState([])
  const [result, setResult] = useState('')
  const { getAll } = useIndexedDB('checkIn')
  const { add } = useIndexedDB('checkpoint')
  const history = useHistory()

  useEffect(() => {
    async function setDataCheckIn() {
      const results = await getAll()
      if (results.length === 0) {
        history.replace('/event')
        return
      }
      setCheckIn(results)
    }
    setDataCheckIn()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const splitData = (url) => {
    const arrData = url.split('/')
    const bib = arrData.pop()
    const slug = arrData.pop()
    const currentSlug = _get(checkIn, '0.slug', '')
    
    if (slug !== currentSlug) {
      return {
        error: `Slug จาก QR Code: ${slug} ไม่ตรงกันกับ Checkin: ${currentSlug}`
      }
    }
    
    return {
      bib,
      slug
    }
  }

  const handleScan = async data => {
    if (!data) return
    if (data === result) return
    const { bib, slug, error } = splitData(data)
    if (error) {
      notification.error({
        message: error,
        duration: 5,
        placement: 'bottomRight'
      })
      setResult(data)
      playErr()
      return
    }
    play()
    setBgFlash(true)
    const checkpointData = {
      _id: bsonObjectid().toHexString(),
      bib,
      slug,
      time: Date.now(),
      position: checkIn[0].position,
      userId: checkIn[0].userId,
      status: 'pending'
    }
    notification.info({
      message: `เพิ่ม ${bib} เรียบร้อย`,
      duration: 1,
      placement: 'bottomRight'
    })
    await add(checkpointData)
    setResult(data)
    setTimeout(() => setBgFlash(false), 300)
  }

  const handleError = err => console.error(err)
  const handleClose = () => history.replace('/info')
  return (
    <Layout style={{ background: bgFlash ? 'green' : '#fff'}}>
      <Space 
        direction="vertical"
        style={{ width: '100%' }}
      >
      <QrReader
        delay={50}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
      />
        <div style={{ padding: 10 }}>
          <Button
            type="danger"
            size="large"
            onClick={handleClose}
            block
          >
            ปิดกล้อง
          </Button>
        </div>
      </Space>
    </Layout>
  )
}

export default Reader