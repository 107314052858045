import React, { useState, useEffect, useContext } from "react";
import { Drawer, PageHeader, Button, Menu, Typography, Modal } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import * as firebase from "firebase";
import { useIndexedDB } from "react-indexed-db";
import { Detector } from "react-detect-offline";
import AuthContext from "./../context";

function Header() {
  const [visible, setVisible] = useState(false);
  const [checkIn, setCheckIn] = useState(null);
  const getAllCheckIn = useIndexedDB("checkIn").getAll;
  const clearCheckIn = useIndexedDB("checkIn").clear;
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);

  useEffect(() => {
    async function init() {
      let checkInData = await getAllCheckIn();
      setCheckIn(checkInData[0]);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleDrawer = () => {
    setVisible(!visible);
  };

  const handleCheckout = async () => {
    await clearCheckIn();
    setVisible(false);
    history.replace("/event");
  };

  const goToAllCheckpoints = () => {
    setVisible(false);
    history.push("/all");
  };

  const goToCheckIn = () => {
    setVisible(false);
    history.push("/event");
  };

  const goToLobby = () => {
    setVisible(false);
    history.push("/info");
  };
  // const goTOLinkNFC = () => {
  //   setVisible(false);
  //   history.push("/linktoUser");
  // };

  const handleLogout = () => {
    Modal.confirm({
      title: "ต้องการออกจากระบบหรือไม่ ?",
      content: "การเข้าสู่ระบบครั้งถัดไป จำเป็นต้องเชื่อมต่ออินเทอร์เน็ต",
      onOk: async () => {
        await clearCheckIn();
        await firebase.auth().signOut();
        history.replace("/login");
      },
      onCancel: () => {
        return;
      },
    });
  };
  return (
    <div>
      <PageHeader
        title={
          <Detector
            render={({ online }) => {
              return (
                <Typography.Title
                  level={4}
                  style={{ color: online ? "green" : "red" }}
                >
                  สถานะ{online ? "ออนไลน์" : "ออฟไลน์"}
                </Typography.Title>
              );
            }}
          />
        }
        extra={[
          <Button key="1" type="link" onClick={handleDrawer}>
            <MenuOutlined style={{ fontSize: 20 }} />
          </Button>,
        ]}
      />
      <Drawer
        title={`${userInfo.email}`}
        placement="left"
        visible={visible}
        onClose={handleDrawer}
      >
        <Menu>
          <Menu.Item key="1" onClick={goToAllCheckpoints}>
            เช็คพอยท์ทั้งหมด
          </Menu.Item>
          {!checkIn && (
            <Menu.Item key="2" onClick={goToCheckIn}>
              เลือกตำแหน่งเช็คอิน
            </Menu.Item>
          )}
          {checkIn && (
            <Menu.Item key="3" onClick={handleCheckout}>
              เปลี่ยนตำแหน่ง
            </Menu.Item>
          )}
          {checkIn && (
            <Menu.Item key="4" onClick={goToLobby}>
              ล็อบบี้
            </Menu.Item>
          )}
          {/* <Menu.Item key="5" onClick={goTOLinkNFC}>
            ผูก NFC กับนักวิ่ง
          </Menu.Item> */}
          <Menu.Item key="6" onClick={handleLogout}>
            ออกจากระบบ
          </Menu.Item>
        </Menu>
      </Drawer>
    </div>
  );
}

export default Header;
