export const DBConfig = {
  name: "checkpointDB",
  version: 4,
  objectStoresMeta: [
    {
      store: "checkIn",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "slug", keypath: "slug", options: { unique: false } },
        { name: "position", keypath: "position", options: { unique: false } },
        { name: "userId", keypath: "userId" },
      ],
    },
    {
      store: "checkpoint",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "bib", keypath: "bib" },
        { name: "position", keypath: "position" },
        { name: "time", keypath: "time" },
        { name: "slug", keypath: "slug" },
        { name: "status", keypath: "status" },
        { name: "userId", keypath: "userId" },
      ],
    },
    {
      store: "userRunIndex",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "name", keypath: "name" },
        { name: "bib", keypath: "bib", options: { unique: false } },
        { name: "RFID", keypath: "RFID", options: { unique: false } },
        { name: "slug", keypath: "slug" },
      ],
    },
  ],
};
