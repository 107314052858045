const firebaseConfig = {
  apiKey: "AIzaSyDrebTZlQXG70dnT-hov2yu9Ct5yb8wN5w",
  authDomain: "thairun-checkpoints.firebaseapp.com",
  databaseURL: "https://thairun-checkpoints.firebaseio.com",
  projectId: "thairun-checkpoints",
  storageBucket: "thairun-checkpoints.appspot.com",
  messagingSenderId: "680648099469",
  appId: "1:680648099469:web:2f543edab0c736bd9d44c4",
  measurementId: "G-GR6Q4NJM8Q"
};

export default firebaseConfig