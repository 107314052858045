import React, { useRef, useEffect, useState } from "react";
import { Button, Input, Card, Typography, Row, Col, Modal } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { toInteger } from "lodash";

const UPDATE_ONE_RUNNER_USER_LINK_TO_NFC = gql`
  mutation linkToNFC($slug: String!, $bib: Int!, $RFID: String!) {
    runnerLinkedNFCToUser(slug: $slug, bib: $bib, RFID: $RFID)
  }
`;
const FIND_USER = gql`
  query searchUserBybib($slug: String!, $bib: Int!) {
    searchByBibToSlug(slug: $slug, bib: $bib) {
      name
      bib
      RFID
      slug
    }
  }
`;

export default function LinktoUser() {
  useEffect(() => {
    window.addEventListener("keypress", keyListener);
    return () => window.removeEventListener("keypress", keyListener);
  });
  //   const rfid = useRef();
  const { Title } = Typography;
  const location = useLocation();
  const [rfid, setRfid] = useState();
  const [showModal, setShowModal] = useState(false);
  const tmp = useRef("");
  const [bib, setBib] = useState();
  const history = useHistory();
  const handleClose = () => history.replace("/info");
  // const [open, setOpen] = useState(false);
  const [linkToNFC] = useMutation(UPDATE_ONE_RUNNER_USER_LINK_TO_NFC);
  const [loadGreeting, { loading, data }] = useLazyQuery(FIND_USER, {
    // variables: { slug: "", bib: 1 },
    skip: true,
  });
  //   return "xx";
  if (loading) return <p>Loading ...</p>;
  console.log(location.state, "slug");
  function keyListener(e) {
    if (rfid) {
      return;
    }
    if (e.key === "Enter") {
      //   rfid.current = tmp.current;
      setRfid(tmp.current);
      tmp.current = "";
    } else {
      //   console.log({ rfid });
      tmp.current += e.key;
    }
    console.log(e, rfid, tmp.current);
  }
  const closeModal = () => setShowModal(false);
  if (rfid) {
    setShowModal(false);
    setRfid();
    setBib();
    // console.log(rfid);
    linkToNFC({
      variables: {
        bib: toInteger(bib),
        slug: location.state,
        RFID: rfid,
      },
    }).then((res) => {
      console.log(res.data);
      Modal.info({
        // type: "success",
        // footer: null,
        // closable: true,
        maskClosable: true,
        content: `${res.data.runnerLinkedNFCToUser}`,
      });
      // history.push("/info");
    });
  }

  // const handleSubmit = () => {
  //   setRfid();
  //   setBib();
  //   console.log(bib);
  //   linkToNFC({
  //     variables: {
  //       bib: toInteger(bib),
  //       slug: location.state,
  //       RFID: rfid,
  //     },
  //   }).then((res) => {
  //     console.log(res.data);
  //     Modal.info({
  //       // type: "success",
  //       // footer: null,
  //       // closable: true,
  //       maskClosable: true,
  //       content: `${res.data.runnerLinkedNFCToUser}`,
  //     });
  //     // history.push("/info");
  //   });
  //   // alert("bib: " + bib + ", rfid: " + rfid + ", slug: " + location.state);
  // };

  //   return <>{rfid.current && `rfid: ${rfid.current}`}</>;
  const handleSubmit2 = async () => {
    tmp.current = "";
    // console.log(bib, "bib");
    await loadGreeting({
      variables: {
        bib: toInteger(bib),
        slug: location.state,
      },
    });
    console.log(data);
    setShowModal(true);
  };
  return (
    <>
      {/* {rfid && `rfid: ${rfid}`} */}
      <Title level={2} style={{ textAlign: "center" }}>
        {" "}
        สแกน NFC เพื่อผูกกับนักวิ่ง{" "}
      </Title>
      {/* {rfid && (
        <>
          <Card
            title="การุณาพิมพ์เลย bib"
            style={{ width: 300, margin: "auto" }}
            actions={[
              <>
                <Row justify="center" align="middle">
                  <Col>
                    <Button onClick={handleSubmit}>Submit bib</Button>
                  </Col>
                </Row>
              </>,
            ]}
          >
            <Input
              type="text"
              value={bib}
              autoFocus
              placeholder="พิมพ์เลข bib ที่ต้องการผูก"
              onChange={(e) => console.log(e) || setBib(e.target.value)}
            />
            <br />
          </Card>
        </>
      )
      } */}
      <Card
        title="การุณาพิมพ์เลย bib"
        style={{ width: 300, margin: "auto" }}
        actions={[
          // <SettingOutlined key="setting" />,
          // <EditOutlined key="edit" />,
          // <EllipsisOutlined key="ellipsis" />,
          <>
            <Row justify="center" align="middle">
              <Col>
                {/* <Button type="primary" style={{ margin: "0 auto" }}>
                  My Button
                </Button> */}
                <Button onClick={handleSubmit2}>Submit bib</Button>
              </Col>
            </Row>
          </>,
        ]}
      >
        <Input
          type="text"
          value={bib}
          autoFocus
          placeholder="พิมพ์เลข bib ที่ต้องการผูก"
          onChange={(e) => console.log(e) || setBib(e.target.value)}
        />
        <br />
      </Card>
      <Modal
        title="สแกน NFC เพื่อทำการผูก"
        visible={showModal}
        onCancel={closeModal}
        onOk={closeModal}
        // maskClosable={true}
      >
        {/* {console.log(data?.searchByBibToSlug)} */}
        <div>{data?.searchByBibToSlug.name}</div>
        <div>{data?.searchByBibToSlug.slug}</div>
        <div>{data?.searchByBibToSlug.bib}</div>
        {/* <div>{data?.searchByBibToSlug.name}</div> */}
      </Modal>
      <div style={{ padding: 10 }}>
        <Button type="danger" size="large" onClick={handleClose} block>
          ปิดหน้านี้
        </Button>
      </div>
    </>
  );
}
