import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import * as firebase from "firebase/app"
import "firebase/auth"
import { ApolloProvider, InMemoryCache, ApolloClient } from '@apollo/client'
import { initDB } from 'react-indexed-db'
import { persistCache } from 'apollo-cache-persist'
import RouterConfig from './Router'
import { DBConfig } from './dbConfig'
import firebaseConfig from './firebaseConfig'
import * as serviceWorker from './serviceWorker';

firebase.initializeApp(firebaseConfig)
initDB(DBConfig)
let client

function ApolloProviderPersisted(props) {
  const [isPersisted, setIsPersisted] = useState()
  const cache = new InMemoryCache()
  useEffect(() => {
    async function hydrate() {
      await persistCache({
        cache,
        storage: window.localStorage
      })
      setIsPersisted(true)
    }
    hydrate()
  })

  client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_URI,
    cache
  })

  if (!isPersisted) {
    return props.persistGate || null
  }

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}

ReactDOM.render(
  <ApolloProviderPersisted>
      <RouterConfig />
  </ApolloProviderPersisted>,
  document.getElementById('root')
);

export default client
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
