import React, { useState, useEffect } from 'react'
import { useIndexedDB } from 'react-indexed-db'
import { List, Layout, Tabs, Empty, Typography, Space, Button, message } from 'antd'
import _reverse from 'lodash/reverse'
import { Detector } from 'react-detect-offline'
import { gql, useMutation } from '@apollo/client'

const PUSH_CHECKPOINT = gql`
mutation pushCheckpoints($record: [CreateManycheckpointInput]) {
  checkpointCreateMany(records: $record) {
    records {
      _id
      slug
      time
      bib
      position
    }
  }
}
`

function AllCheckpoints() {
  const [checkpoints, setCheckpoints] = useState([])
  const [pushCheckpoint] = useMutation(PUSH_CHECKPOINT) 
  const getAllCheckpoint = useIndexedDB('checkpoint').getAll
  const updateCheckpoint = useIndexedDB('checkpoint').update

  useEffect(() => {
    async function init() {
      let checkpointData = await getAllCheckpoint()
      _reverse(checkpointData)
      setCheckpoints(checkpointData)
    }
    init()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePushData = async () => {
    const prepareData = checkpoints
      .filter(({ status }) => status === 'pending')
      .map(({ _id, bib, position, slug, time, userId }) => ({
        _id,
        bib: parseInt(bib, 10),
        position: parseInt(position, 10),
        slug,
        time,
        userId
      }))
    if (prepareData.length === 0) return

    try {
      await pushCheckpoint({
        variables: {
          record: prepareData
        }
      })
      const updatedCheckpoints = checkpoints.map(({id, _id, position, slug, time, userId }) => {
        const data = {
          id,
          _id,
          position,
          slug,
          time,
          userId,
          status: 'sent'
        }
        updateCheckpoint(data)
        return data
      })
      setCheckpoints(updatedCheckpoints)
      message.success('ส่งข้อมูลเรียบร้อย')
    } catch (e) {
      console.error('e', e)
    }
  }
  
  const pendingData = checkpoints.filter(({ status }) => status === 'pending')
  const sentData = checkpoints.filter(({ status }) => status === 'sent')
  const isPendingEmpty = pendingData.length === 0
  const isSentEmpty = sentData.length === 0

  const $compPending = <div style={{ maxHeight: 300, overflow: 'scroll', padding: 10 }}>
    <List
      bordered
      dataSource={pendingData}
      renderItem={item => {
        const time = new Date(item.time)
        return <List.Item>
          {item.position} (pos.) |
          {item.bib} (bib) | 
          {time.toLocaleTimeString('th-TH')} |
          {item.slug}
        </List.Item>
      }}
    />
  </div>
  const $compSent = <div style={{ maxHeight: 300, overflow: 'scroll', padding: 10 }}>
    <List
      bordered
      dataSource={sentData}
      renderItem={item => {
        const time = new Date(item.time)
        return <List.Item>
          {item.position} (pos.) |
          {item.bib} (bib) | 
          {time.toLocaleTimeString('th-TH')} |
          {item.slug}
        </List.Item>
      }}
    />
  </div>
  return (
    <Layout>
      <Layout.Content
        style={{
          padding: 10,
          backgroundColor: '#fff',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Typography.Title level={4}>ข้อมูลทั้งหมด</Typography.Title>
        </div>
        <Tabs defaultActiveKey="1" centered>
          <Tabs.TabPane tab={`Pending (${pendingData.length})`} key="1">
            {!isPendingEmpty ? $compPending : <Empty />}
          </Tabs.TabPane>
          <Tabs.TabPane tab={`Sent (${sentData.length})`} key="2">
            {!isSentEmpty ? $compSent : <Empty />}
          </Tabs.TabPane>
        </Tabs>
        {!isPendingEmpty &&
            <Space
              direction="vertical"
              style={{ width: '100%' }}
            >
                <Detector 
                  render={({ online }) => {
                    return <Button
                      size="large"
                      onClick={handlePushData}
                      disabled={!online}
                      block
                    >
                      {online ? `ส่งข้อมูล (${pendingData.length})` : 'ออฟไลน์'}
                    </Button>
                  }} 
                />
            </Space>
          }
      </Layout.Content>
    </Layout>
  )
}

export default AllCheckpoints